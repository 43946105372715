<template>
  <div class="product-filter box1">
    <div action="#" class="form mb-0">
      <h4>Filter By</h4>
      <div class="filter-options price-filter">
        <h6>Price</h6>
        <input type="range" class="form-control-range mb-3" v-model="price" :min="minPrice" :max="maxPrice"
          @change="priceChanged" data-toggle="tooltip" data-placement="top" :title="price" />
        <ul class="list">
          <li><input type="text" class="form-control" v-model="minPrice" readonly></li>
          <li><span>to</span></li>
          <li><input type="text" class="form-control" v-model="price" readonly></li>
        </ul>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#capacity-filter" role="button" aria-expanded="true"
          aria-controls="capacity-filter">
          Capacity (kg)
        </h6>
        <div class="wrapper collapse show" id="capacity-filter">
          <VueMultiselect v-model="capacity" :options="capacities" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select capacities" :searchable="false" selectLabel="" deselectLabel=""
            @input="capacityChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#feature-filter" role="button" aria-expanded="true"
          aria-controls="feature-filter">
          Key feature
        </h6>
        <div class="wrapper collapse show" id="feature-filter">
          <ul class="list">
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="autoDry" id="feature-0" @change="autoDryChanged" />
                <label for="feature-0">autoDry</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="super40" id="feature-3" @change="super40Changed" />
                <label for="feature-3">super40</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="reloadFunction" id="feature-4" @change="reloadFunctionChanged" />
                <label for="feature-4">Reload function</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="display" id="feature-5" @change="displayChanged" />
                <label for="feature-5">display</label>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#noise-filter" role="button" aria-expanded="true"
          aria-controls="noise-filter">
          Noise level
        </h6>
        <div class="wrapper collapse show" id="noise-filter">
          <VueMultiselect v-model="noise" :options="noiseLevels" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select noise levels" :searchable="false" selectLabel=""
            deselectLabel="" @input="noiseChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#installation-filter" role="button" aria-expanded="true"
          aria-controls="installation-filter">
          Installation type
        </h6>
        <div class="wrapper collapse show" id="installation-filter">
          <VueMultiselect v-model="installation" :options="instTypes" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select installation types" :searchable="false" selectLabel=""
            deselectLabel="" @input="installationChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#height-filter" role="button" aria-expanded="true"
          aria-controls="height-filter">
          Height
        </h6>
        <div class="wrapper collapse show" id="height-filter">
          <VueMultiselect v-model="height" :options="heights" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select heights" :searchable="false" selectLabel="" deselectLabel=""
            @input="heightChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#width-filter" role="button" aria-expanded="true"
          aria-controls="width-filter">
          Width
        </h6>
        <div class="wrapper collapse show" id="width-filter">
          <VueMultiselect v-model="width" :options="widths" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select widths" :searchable="false" selectLabel="" deselectLabel=""
            @input="widthChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#depth-filter" role="button" aria-expanded="true"
          aria-controls="depth-filter">
          Depth
        </h6>
        <div class="wrapper collapse show" id="depth-filter">
          <VueMultiselect v-model="depth" :options="depths" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select depths" :searchable="false" selectLabel="" deselectLabel=""
            @input="depthChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#color-filter" role="button" aria-expanded="true"
          aria-controls="color-filter">
          Colour
        </h6>
        <div class="wrapper collapse show" id="color-filter">
          <VueMultiselect v-model="color" :options="colors" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select colours" :searchable="false" selectLabel="" deselectLabel=""
            @input="colorChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#feet-filter" role="button" aria-expanded="true"
          aria-controls="feet-filter">
          Adjustable feet
        </h6>
        <div class="wrapper collapse show" id="feet-filter">
          <VueMultiselect v-model="adjustableFeet" :options="adjustableFeets" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select adjustable feets" :searchable="false" selectLabel=""
            deselectLabel="" @input="adjustableFeetChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#program-filter" role="button" aria-expanded="true"
          aria-controls="program-filter">
          List of programs
        </h6>
        <div class="wrapper collapse show" id="program-filter">
          <VueMultiselect v-model="program" :options="programs" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select programs" :searchable="false" selectLabel="" deselectLabel=""
            @input="programChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#connectivity-filter" role="button" aria-expanded="true"
          aria-controls="connectivity-filter">
          Connectivity type
        </h6>
        <div class="wrapper collapse show" id="connectivity-filter">
          <VueMultiselect v-model="connectivity" :options="connectivities" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select connectivity types" :searchable="false" selectLabel=""
            deselectLabel="" @input="connectivityChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#loading-filter" role="button" aria-expanded="true"
          aria-controls="loading-filter">
          Loading type
        </h6>
        <div class="wrapper collapse show" id="loading-filter">
          <VueMultiselect v-model="loadingType" :options="loadingTypes" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select loading types" :searchable="false" selectLabel=""
            deselectLabel="" @input="loadingTypeChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#energy-filter" role="button" aria-expanded="true"
          aria-controls="energy-filter">
          Energy efficiency class
        </h6>
        <div class="wrapper collapse show" id="energy-filter">
          <VueMultiselect v-model="energy" :options="energyClasses" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select energy classes" :searchable="false" selectLabel=""
            deselectLabel="" @input="energyChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#hinge-filter" role="button" aria-expanded="true"
          aria-controls="hinge-filter">
          Door hinge
        </h6>
        <div class="wrapper collapse show" id="hinge-filter">
          <VueMultiselect v-model="doorHinge" :options="doorHinges" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select door hinges" :searchable="false" selectLabel="" deselectLabel=""
            @input="doorHingeChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#hinge-filter" role="button" aria-expanded="true"
          aria-controls="hinge-filter">
          Cycle options
        </h6>
        <div class="wrapper collapse show" id="hinge-filter">
          <VueMultiselect v-model="cycleOption" :options="cycleOptions" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select cycle options" :searchable="false" selectLabel=""
            deselectLabel="" @input="cycleOptionChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#dryer-filter" role="button" aria-expanded="true"
          aria-controls="dryer-filter">
          Dryer Type
        </h6>
        <div class="wrapper collapse show" id="dryer-filter">
          <VueMultiselect v-model="dryerType" :options="dryerTypes" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select dryer types" :searchable="false" selectLabel="" deselectLabel=""
            @input="dryerTypeChanged" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import ProductService from "@/services/product.service";
import VueMultiselect from "vue-multiselect";

export default {
  name: "CondenserTumbleDryerFilters",
  data: function () {
    return {
      noiseLevels: [],
      serieses: [],
      capacities: [],
      energyClasses: [],
      instTypes: [],
      heights: [],
      colors: [],
      widths: [],
      depths: [],
      adjustableFeets: [],
      programs: [],
      connectivities: [],
      loadingTypes: [],
      doorHinges: [],
      dryerTypes: [],
      cycleOptions: [],
      minPrice: 0,
      maxPrice: 0,

      price: 0,
      noise: [],
      series: [],
      capacity: [],
      energy: [],
      installation: [],
      height: [],
      color: [],
      width: [],
      depth: [],
      adjustableFeet: [],
      program: [],
      connectivity: [],
      loadingType: [],
      doorHinge: [],
      dryerType: [],
      cycleOption: [],
      autoDry: false,
      super40: false,
      reloadFunction: false,
      display: false
    };
  },
  components: {
    VueMultiselect,
  },
  watch: {
    // call again the method if the route changes
    selectedBrands: function () {
      this.bindValues();
    },
  },
  mounted() {
    this.bindValues();
    if (this.selectedFilters.category == this.selectedSubCategory.id) {
      this.existingFilters();
    }
  },
  computed: {
    selectedSubCategory() {
      return this.$store.state.subCategory.selectedSubCategory;
    },
    selectedBrands() {
      return this.$store.state.brand.selectedBrands;
    },
    selectedFilters() {
      return this.$store.state.filters.selectedFilters;
    }
  },
  methods: {
    bindValues() {
      ProductService.minMaxPriceFor(
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.minPrice = response.price__min;
        this.maxPrice = response.price__max;
        this.price = response.price__max;
        if (this.selectedFilters.price__lte) {
          this.price = Number(this.selectedFilters.price__lte);
        }
      });
      ProductService.valuesFor(
        "attr_noise_marketing",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.noiseLevels = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_cap_cotton_2010",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.capacities = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "series",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.serieses = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_energy_class_2010",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.energyClasses = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_noise_marketing",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.noiseLevels = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_inst_type",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.instTypes = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "series",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.serieses = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_height",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.heights = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_width",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.widths = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_depth",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.depths = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_col_main",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.colors = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_feet_adj",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.adjustableFeets = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_list_prgr",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.programs = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_homeconnect_connectivity",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.connectivities = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_loading",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.loadingTypes = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_door_hinge",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.doorHinges = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_dryer_type",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.dryerTypes = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_add_options",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.cycleOptions = response.filter(
          (item) => !["", null].includes(item)
        );
      });
    },


    priceChanged() {
      let filters = { price__lte: this.price };
      this.$emit("changeFilters", filters);
    },

    noiseChanged() {
      let filters = {
        attr_noise_marketing__in: _.map(this.noise, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    seriesChanged() {
      let filters = {
        series__in: _.map(this.series, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    capacityChanged() {
      let filters = {
        attr_cap_cotton_2010__in: _.map(this.capacity, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    energyChanged() {
      let filters = {
        attr_energy_class_2010__in: _.map(this.energy, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    installationChanged() {
      let filters = {
        attr_inst_type__in: _.map(this.installation, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    heightChanged() {
      let filters = {
        attr_height__in: _.map(this.height, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    widthChanged() {
      let filters = {
        attr_width__in: _.map(this.width, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    depthChanged() {
      let filters = {
        attr_depth__in: _.map(this.depth, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    colorChanged() {
      let filters = {
        attr_col_main__in: _.map(this.color, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    adjustableFeetChanged() {
      let filters = {
        attr_feet_adj__in: _.map(this.adjustableFeet, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    programChanged() {
      let filters = {
        attr_list_prgr__in: _.map(this.program, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    connectivityChanged() {
      let filters = {
        attr_homeconnect_connectivity__in: _.map(this.connectivity, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    loadingTypeChanged() {
      let filters = {
        attr_loading__in: _.map(this.loadingType, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    doorHingeChanged() {
      let filters = {
        attr_door_hinge__in: _.map(this.doorHinge, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    cycleOptionChanged() {
      let filters = {
        attr_add_options__in: _.map(this.cycleOption, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    dryerTypeChanged() {
      let filters = {
        attr_dryer_type__in: _.map(this.dryerType, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    autoDryChanged() {
      let filters = {
        attr_autodry: this.autoDry ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    super40Changed() {
      let filters = {
        attr_super40: this.super40 ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    reloadFunctionChanged() {
      let filters = {
        attr_reloadfunction: this.reloadFunction ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    displayChanged() {
      let filters = {
        attr_indicator_count_down: this.display ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    existingFilters() {
      this.autoDry = this.selectedFilters.attr_autodry;
      this.super40 = this.selectedFilters.attr_super40;
      this.reloadFunction = this.selectedFilters.attr_reloadfunction;
      this.display = this.selectedFilters.attr_indicator_count_down;

      if (this.selectedFilters.attr_noise_marketing__in) {
        let sortedNoises = [];
        let unsortedNoises = this.selectedFilters.attr_noise_marketing__in.split('","');
        unsortedNoises.map((noise) => {
          noise = noise.replaceAll('"', '')
          sortedNoises.push(noise)
        });
        this.noise = sortedNoises;
      }

      if (this.selectedFilters.attr_cap_cotton_2010__in) {
        let sortedCapacities = [];
        let unsortedCapacities = this.selectedFilters.attr_cap_cotton_2010__in.split('","');
        unsortedCapacities.map((capacity) => {
          capacity = capacity.replaceAll('"', '')
          sortedCapacities.push(capacity)
        });
        this.capacity = sortedCapacities;
      }

      if (this.selectedFilters.series__in) {
        let sortedSeries = [];
        let unsortedSeries = this.selectedFilters.series__in.split('","');
        unsortedSeries.map((series) => {
          series = series.replaceAll('"', '')
          sortedSeries.push(series)
        });
        this.series = sortedSeries;
      }

      if (this.selectedFilters.attr_inst_type__in) {
        let sortedInstallations = [];
        let unsortedInstallations = this.selectedFilters.attr_inst_type__in.split('","');
        unsortedInstallations.map((installation) => {
          installation = installation.replaceAll('"', '')
          sortedInstallations.push(installation)
        });
        this.installation = sortedInstallations;
      }
      if (this.selectedFilters.attr_height__in) {
        let sortedHeights = [];
        let unsortedHeights = this.selectedFilters.attr_height__in.split('","');
        unsortedHeights.map((height) => {
          height = height.replaceAll('"', "");
          sortedHeights.push(height);
        });
        this.height = sortedHeights;
      }
      if (this.selectedFilters.attr_width__in) {
        let sortedWidths = [];
        let unsortedWidths = this.selectedFilters.attr_width__in.split('","');
        unsortedWidths.map((width) => {
          width = width.replaceAll('"', "");
          sortedWidths.push(width);
        });
        this.width = sortedWidths;
      }
      if (this.selectedFilters.attr_depth__in) {
        let sortedDepths = [];
        let unsortedDepths = this.selectedFilters.attr_depth__in.split('","');
        unsortedDepths.map((depth) => {
          depth = depth.replaceAll('"', "");
          sortedDepths.push(depth);
        });
        this.depth = sortedDepths;
      }
      if (this.selectedFilters.attr_col_main__in) {
        let sortedColors = [];
        let unsortedColors = this.selectedFilters.attr_col_main__in.split('","');
        unsortedColors.map((color) => {
          color = color.replaceAll('"', "");
          sortedColors.push(color);
        });
        this.color = sortedColors;
      }
      if (this.selectedFilters.attr_energy_class_2010__in) {
        let sortedEnergies = [];
        let unsortedEnergies = this.selectedFilters.attr_energy_class_2010__in.split('","');
        unsortedEnergies.map((energy) => {
          energy = energy.replaceAll('"', '')
          sortedEnergies.push(energy)
        });
        this.energy = sortedEnergies;
      }
      if (this.selectedFilters.attr_feet_adj__in) {
        let sortedFeets = [];
        let unsortedFeets = this.selectedFilters.attr_feet_adj__in.split('","');
        unsortedFeets.map((feet) => {
          feet = feet.replaceAll('"', "");
          sortedFeets.push(feet);
        });
        this.adjustableFeet = sortedFeets;
      }
      if (this.selectedFilters.attr_list_prgr__in) {
        let sortedPrograms = [];
        let unsortedPrograms = this.selectedFilters.attr_list_prgr__in.split(
          '","'
        );
        unsortedPrograms.map((program) => {
          program = program.replaceAll('"', "");
          sortedPrograms.push(program);
        });
        this.program = sortedPrograms;
      }
      if (this.selectedFilters.attr_homeconnect_connectivity__in) {
        let sortedConnectivities = [];
        let unsortedConnectivities = this.selectedFilters.attr_homeconnect_connectivity__in.split(
          '","'
        );
        unsortedConnectivities.map((connectivity) => {
          connectivity = connectivity.replaceAll('"', "");
          sortedConnectivities.push(connectivity);
        });
        this.connectivity = sortedConnectivities;
      }
      if (this.selectedFilters.attr_loading__in) {
        let sortedLoadings = [];
        let unsortedLoadings = this.selectedFilters.attr_loading__in.split('","');
        unsortedLoadings.map((loading) => {
          loading = loading.replaceAll('"', "");
          sortedLoadings.push(loading);
        });
        this.loadingType = sortedLoadings;
      }
      if (this.selectedFilters.attr_door_hinge__in) {
        let sortedHinges = [];
        let unsortedHinges = this.selectedFilters.attr_door_hinge__in.split('","');
        unsortedHinges.map((hinge) => {
          hinge = hinge.replaceAll('"', "");
          sortedHinges.push(hinge);
        });
        this.doorHinge = sortedHinges;
      }
      if (this.selectedFilters.attr_add_options__in) {
        let sortedOptions = [];
        let unsortedOptions = this.selectedFilters.attr_add_options__in.split('","');
        unsortedOptions.map((option) => {
          option = option.replaceAll('"', '')
          sortedOptions.push(option)
        });
        this.cycleOption = sortedOptions;
      }
      if (this.selectedFilters.attr_dryer_type__in) {
        let sortedDryers = [];
        let unsortedDryers = this.selectedFilters.attr_dryer_type__in.split('","');
        unsortedDryers.map((dryer) => {
          dryer = dryer.replaceAll('"', '')
          sortedDryers.push(dryer)
        });
        this.dryerType = sortedDryers;
      }
    }
  },
};
</script>

<style scoped></style>